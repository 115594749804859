<template>
  <a-upload
    name="file"
    v-has="'material:upload'"
    :showUploadList="false"
    accept=".jpg,.psd,.png,.zip"
    :multiple="true"
    :before-upload="beforeUpload"
    :custom-request="handleRequest"
  >
    <a-button v-if="!isTable" :loading="loading" class="mr-3" icon="upload" type="primary">{{ btnText }} </a-button>
    <a-spin v-else :spinning="loading" style="margin-left:15px">
      <a-icon slot="indicator" type="loading" style="font-size: 24px" spin />
      <a>{{ btnText }}</a>
    </a-spin>
  </a-upload>
</template>

<script>
import Vue from 'vue'
import { USER_INFO } from '@/store/mutation-types'
import { changeDpiBlob } from '@/utils/changeDpi.js'
import * as imageConversion from 'image-conversion'
import { UploadFileToOssMixins } from '@/mixins/UploadFileToOssMixins'
import { getAction, postAction } from '@/api/manage'
import PSD from 'psd.js'
const JSZip = require('jszip')
export default {
  mixins: [UploadFileToOssMixins],
  props: {
    btnText: {
      default: '上传',
      type: String
    },
    isTable: {
      default: false,
      type: Boolean
    },
    materialCategoryId: {
      default: '',
      type: String
    },
    upType: {
      default: 'JPG',
      type: String
    },
    sizeList: {
      default: () => [],
      type: Array
    },
    loadData: {
      type: Function,
      default: () => {}
    },
    saveUrl: {
      type: String,
      default: ''
    },
    btnType: {
      type: String,
      default: 'original'
    },
    taskDetail: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      loading: false,
      fileList: [],
      defaultMaxHeight: 0,
      defaultMinHeight: 0,
      defaultWidth: 0,
      defaultType: 'JPG',
      /*       uploadFileList:[],
      topProgress:0,
      bottomProgress:0 */
      ossUploadDatas: {},
      upList: [],
      finishFileList: [],
      userInfo: {}
    }
  },
  created() {
    this.userInfo = Vue.ls.get(USER_INFO)
  },
  watch: {
    upList() {
      this.startProgress()
    }
  },
  methods: {
    handleRequest() {
      return
    },
    startProgress() {
      this.$emit('startProgress', this.upList, this.finishFileList, this.upType)
    },
    changeProgress() {
      this.$emit('changeProgress', this.upList, this.finishFileList, this.upType)
    },
    changeTransProgress() {
      this.$emit('changeTransProgress', this.upList, this.upType)
    },
    endProgress() {
      this.finishFileList = []
      this.$emit('endProgress', this.upType)
    },
    base64ToFile(urlData, fileName) {
      let arr = urlData.split(',')
      let mime = arr[0].match(/:(.*?);/)[1]
      let bytes = atob(arr[1]) // 解码base64
      let n = bytes.length
      let ia = new Uint8Array(n)
      while (n--) {
        ia[n] = bytes.charCodeAt(n)
      }
      return new File([ia], fileName, { type: mime })
    },
    async beforeUpload(file) {
      /*       if (this.btnType == 'original') {
        this.defaultMaxHeight = this.sizeList[0].size.psdMaxHeight
        this.defaultMinHeight = this.sizeList[0].size.psdMinHeight
        this.defaultWidth = this.sizeList[0].size.psdWidth
        this.defaultType = this.sizeList[0].size.psdFormat
      } */
      this.loading = true
      this.handleUpload({ file })
    },
    async handleUpload(info) {
      let file = info.file
      let type = file.name
        .split('.')
        .slice(-1)[0]
        .toLowerCase()
      if (type == 'psd' || type == 'PSD') {
        this.fileList.push(file)
        this.upList.push(file)
        this.psdUpload(file)
      } else if (type == 'jpg' || type == 'JPG' || type == 'PNG' || type == 'png') {
        this.fileList.push(file)
        this.upList.push(file)
        this.jpgUpload(file)
      } else if (type == 'zip' || type == 'ZIP') {
        const zip = new JSZip()
        // 解压Zip压缩包，参数默认是二进制
        const zipData = await zip.loadAsync(file)
        this.upList = [...this.upList, ...Object.values(zipData.files).filter(item => !item.dir)]
        for (let key in zipData.files) {
          if (!zipData.files[key].dir) {
            // 判断是否是目录
            if (/\.(xlsx)$/.test(zipData.files[key].name)) {
              // 判断是否是xlsx文件
              let base = await zip.file(zipData.files[key].name).async('base64') // 以base64输出文本内容
              // Base64 转 File 对象
              const result = this.dataURLtoFile(base, zipData.files[key].name)
              this.fileList.push(result)
              console.log(result, '最终解压后的File对象')
            } else {
              let zipType = zipData.files[key].name
                .split('.')
                .slice(-1)[0]
                .toLowerCase()
              if (zipType == 'psd' || zipType == 'PSD') {
                let base = await zip.file(zipData.files[key].name).async('base64') // 以base64输出文本内容
                // Base64 转 File 对象
                const result = this.dataURLtoFile(base, zipData.files[key].name)
                this.fileList.push(result)
                this.ossUploadData = this.ossUploadDatas[result.name]
                this.ossFilePolicy[result.name] = this.ossUploadData
                this.psdUpload(result)
              } else if (zipType == 'jpg' || zipType == 'JPG') {
                let base = await zip.file(zipData.files[key].name).async('base64') // 以base64输出文本内容
                // Base64 转 File 对象
                const result = this.dataURLtoFile(base, zipData.files[key].name)
                this.fileList.push(result)
                this.ossUploadData = this.ossUploadDatas[result.name]
                this.ossFilePolicy[result.name] = this.ossUploadData
                this.jpgUpload(result)
              }
              console.log(zipData.files[key], '最终解压后的File对象')
            }
          }
        }
      }
    },
    dataURLtoFile(dataURL, fileName, fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
      /**
       * 注意：【不同文件不同类型】，例如【图片类型】就是`data:image/png;base64,${dataURL}`.split(',')
       * 下面的是【excel文件(.xlsx尾缀)】的文件类型拼接，一个完整的 base64 应该
       * 是这样的,例如： data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAMAAACelLz8AAAABGdBTUEAALGPC/xhBQAAACBjSFJN
       */

      const arr = `data:${fileType};base64,${dataURL}`.split(',')
      const mime = arr[0].match(/:(.*?);/)[1]
      const bstr = atob(arr[1])
      let n = bstr.length
      const u8arr = new Uint8Array(n)
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }
      let blob = new File([u8arr], fileName, { type: mime })
      return blob
    },
    fileToBlob(file) {
      // 创建 FileReader 对象
      let reader = new FileReader()
      return new Promise(resolve => {
        // FileReader 添加 load 事件
        reader.addEventListener('load', e => {
          let blob
          if (typeof e.target.result === 'object') {
            blob = new Blob([e.target.result])
          } else {
            blob = e.target.result
          }
          console.log(Object.prototype.toString.call(blob))
          resolve(blob)
        })
        // FileReader 以 ArrayBuffer 格式 读取 File 对象中数据
        reader.readAsArrayBuffer(file)
      })
    },
    async jpgUpload(file) {
      let that = this
      let fileSize = file.size
      that.upLoadEnd(file, that.upType, that.btnType, fileSize)
      /*       const upFile = await this.fileToBlob(file)
      changeDpiBlob(upFile, 300).then(blob => {
        let newFile = new File([blob], file.name, { type: 'contentType', lastModified: Date.now() })
        setTimeout(() => {
          that.upLoadEnd(newFile, that.upType, that.btnType, fileSize)
        }, 0)
      }) */
      /*       return new Promise((resolve, reject) => {
        let _URL = window.URL || window.webkitURL
        let isLt2M = file.size / 1024 / 1024 > 4 // 判定图片大小是否小于4MB
        // 这里需要计算出图片的长宽
        let img = new Image()
        img.onload = function() {
          file.width = img.width // 获取到width放在了file属性上
          file.height = img.height // 获取到height放在了file属性上
          let valid = img.width > 680 // 图片宽度大于2000
          // console.log(11, file)
          // 这里我只判断了图片的宽度,compressAccurately有多个参数时传入对象
          if (valid || isLt2M) {
            imageConversion
              .compressAccurately(file, {
                size: ((file.size * (that.defaultWidth / file.width)) / 1000).toFixed(0),
                width: that.defaultWidth
              })
              .then(res => {
                var newFile = new File([res], file.name, { type: 'contentType', lastModified: Date.now() })
                setTimeout(() => {
                  that.upLoadEnd(newFile, that.upType, that.btnType, fileSize)
                }, 0)
                resolve(res)
              })
              .catch(err => {
                that.fileList.splice(
                  that.fileList.findIndex(item => item.name == file.name),
                  1
                )
                if (!that.fileList.length) {
                  that.loading = false
                }
                that.changeProgress()
                that.$message.error(file.name + '上传失败')
              })
          } else resolve(file)
        }
        // 需要把图片赋值
        img.src = _URL.createObjectURL(file)
      }) */
    },
    //psd转jpg并压缩修改宽度
    async psdUpload(psdFile) {
      let that = this
      let fileSize = psdFile.size
      var url = URL.createObjectURL(psdFile)
      const psd = await PSD.fromURL(url)
      // 图片不大于4m,宽度不大于2000
      let file = this.base64ToFile(psd.image.toBase64(), psdFile.name)
      setTimeout(() => {
        that.upLoadEnd(file, that.upType, that.btnType, fileSize, psdFile)
      }, 0)
      /*       return new Promise((resolve, reject) => {
        let _URL = window.URL || window.webkitURL
        // 这里需要计算出图片的长宽
        let img = new Image()
        img.onload = function() {
          file.width = img.width // 获取到width放在了file属性上
          file.height = img.height // 获取到height放在了file属性上
          // console.log(11, file)
          // 这里我只判断了图片的宽度,compressAccurately有多个参数时传入对象
          imageConversion
            .compressAccurately(file, {
              size: ((file.size * (690 / file.width)) / 1000).toFixed(0),
              width: that.defaultWidth
            })
            .then(res => {
              var newFile = new File([res], file.name, { type: 'contentType', lastModified: Date.now() })
              setTimeout(() => {
                that.upLoadEnd(newFile, that.upType, that.btnType, fileSize, psdFile)
              }, 0)
              resolve(res)
            })
            .catch(err => {
              that.fileList.splice(
                that.fileList.findIndex(item => item.name == file.name),
                1
              )
              if (!that.fileList.length) {
                that.loading = false
              }
              that.changeProgress()
              that.$message.error(file.name + '上传失败')
            })
        }
        // 需要把图片赋值
        img.src = _URL.createObjectURL(file)
      }) */
    },
    async upLoadEnd(file, type, btnType, fileSize, psdFile) {
      if (psdFile) {
        this.changeTransProgress()
        let uploadPath = ''
        if (
          this.taskDetail.productionName &&
          this.taskDetail.chapterSequenceNo &&
          this.taskDetail.platformName &&
          this.taskDetail.taskName
        ) {
          uploadPath =
            this.taskDetail.platformName +
            '/' +
            this.taskDetail.productionName +
            '/' +
            this.taskDetail.chapterSequenceNo +
            '/' +
            this.taskDetail.taskName+
            '/'+
            this.getGuid()
        }
        if (!uploadPath) {
          uploadPath=this.getGuid()
        }
        this.tokenAxios({
          url: '/oss',
          method: 'get'
        })
          .then(async res => {
            let policy = {
              policy: res.data.policy,
              signature: res.data.signature,
              ossaccessKeyId: res.data.accessid,
              dir: res.data.dir,
              host: res.data.host,
              key: `${res.data.dir}glxt/sck/${this.userInfo.realname}/${uploadPath}/${file.name}`
            }
            this.ossUploadDatas[file.name] = policy
            this.ossFilePolicy[file.name] = policy
            let formData = new FormData()
            for (let key in this.ossUploadDatas[file.name]) {
              formData.append(key, this.ossUploadDatas[file.name][key])
            }
            formData.append('file', file)
            let that = this
            postAction(policy.host, formData)
              .then(async rest => {
                console.log('成功OSS', policy.host, formData)
                Object.assign(file, { status: 'done' })
                let params1 = this.getSaveFileParams(file)
                this.tokenAxios({
                  url: '/oss',
                  method: 'get'
                })
                  .then(async res => {
                    let date = new Date()
                    let policy = {
                      policy: res.data.policy,
                      signature: res.data.signature,
                      ossaccessKeyId: res.data.accessid,
                      dir: res.data.dir,
                      host: res.data.host,
                      key: `${res.data.dir}glxt/sck/psd/${this.userInfo.realname}/${uploadPath}/${psdFile.name}`
                    }
                    this.ossUploadDatas[psdFile.name] = policy
                    this.ossFilePolicy[psdFile.name] = policy
                    let formData = new FormData()
                    for (let key in this.ossUploadDatas[psdFile.name]) {
                      formData.append(key, this.ossUploadDatas[psdFile.name][key])
                    }
                    formData.append('file', psdFile)
                    let that = this
                    postAction(policy.host, formData)
                      .then(async rest => {
                        console.log('成功OSS', policy.host, formData)
                        Object.assign(psdFile, { status: 'done' })
                        let params2 = this.getSaveFileParams(psdFile)
                        let data = {
                          url: params1.filePath,
                          materialCategoryId: that.materialCategoryId,
                          fileName: psdFile.name,
                          sourceUrl: params2.filePath
                        }
                        const res = await postAction(that.saveUrl, data)
                        try {
                          if (res.code == 200) {
                            that.fileList.splice(
                              that.fileList.findIndex(item => item.name == file.name),
                              1
                            )
                            that.loadData()
                            that.changeProgress()
                            that.finishFileList.push(file.name)
                            if (!that.fileList.length) {
                              that.loading = false
                              that.upList = []
                              that.endProgress()
                              that.$message.success('上传完成')
                            }
                          } else {
                            that.fileList.splice(
                              that.fileList.findIndex(item => item.name == file.name),
                              1
                            )
                            if (!that.fileList.length) {
                              that.loading = false
                            }
                            that.changeProgress()
                            that.$message.error(file.name + '上传失败')
                          }
                        } catch (err) {
                          that.fileList.splice(
                            that.fileList.findIndex(item => item.name == file.name),
                            1
                          )
                          if (!that.fileList.length) {
                            that.loading = false
                          }
                          that.changeProgress()
                          that.$message.error(file.name + '上传失败')
                        }
                        that.removeOssFilePolicy(file)
                        that.avatarLoading = false
                      })
                      .catch(err => {
                        console.log('失败OSS', policy.host, formData)
                        that.fileList.splice(
                          that.fileList.findIndex(item => item.name == file.name),
                          1
                        )
                        if (!that.fileList.length) {
                          that.loading = false
                        }
                        that.changeProgress()
                        that.$message.error(file.name + '上传失败')
                      })
                  })
                  .catch(err => {
                    this.$message.error('获取OSS签名失败')
                  })
              })
              .catch(err => {
                console.log('失败OSS', policy.host, formData)
                that.fileList.splice(
                  that.fileList.findIndex(item => item.name == file.name),
                  1
                )
                if (!that.fileList.length) {
                  that.loading = false
                }
                that.changeProgress()
                that.$message.error(file.name + '上传失败')
              })
          })
          .catch(err => {
            this.$message.error('获取OSS签名失败')
          })
      } else {
        this.changeTransProgress()
        this.tokenAxios({
          url: '/oss',
          method: 'get'
        })
          .then(async res => {
            let policy = {
              policy: res.data.policy,
              signature: res.data.signature,
              ossaccessKeyId: res.data.accessid,
              dir: res.data.dir,
              host: res.data.host,
              key: `${res.data.dir}glxt/sck/${this.userInfo.realname}/${file.name}`
            }
            this.ossUploadDatas[file.name] = policy
            this.ossFilePolicy[file.name] = policy
            let formData = new FormData()
            for (let key in this.ossUploadDatas[file.name]) {
              formData.append(key, this.ossUploadDatas[file.name][key])
            }
            formData.append('file', file)
            let that = this
            postAction(policy.host, formData)
              .then(async rest => {
                console.log('成功OSS', policy.host, formData)
                Object.assign(file, { status: 'done' })
                let params = this.getSaveFileParams(file)
                let data = {
                  url: params.filePath,
                  materialCategoryId: that.materialCategoryId,
                  fileName: file.name,
                  sourceUrl: params.filePath
                }
                const res = await postAction(that.saveUrl, data)
                try {
                  if (res.code == 200) {
                    that.fileList.splice(
                      that.fileList.findIndex(item => item.name == file.name),
                      1
                    )
                    that.loadData()
                    that.changeProgress()
                    that.finishFileList.push(file.name)
                    if (!that.fileList.length) {
                      that.loading = false
                      that.upList = []
                      that.endProgress()
                      that.$message.success('上传完成')
                    }
                  } else {
                    that.fileList.splice(
                      that.fileList.findIndex(item => item.name == file.name),
                      1
                    )
                    if (!that.fileList.length) {
                      that.loading = false
                    }
                    that.changeProgress()
                    that.$message.error(file.name + '上传失败,' + res.msg)
                  }
                } catch (err) {
                  that.fileList.splice(
                    that.fileList.findIndex(item => item.name == file.name),
                    1
                  )
                  if (!that.fileList.length) {
                    that.loading = false
                  }
                  that.changeProgress()
                  that.$message.error(file.name + '上传失败')
                }
                that.removeOssFilePolicy(file)
                that.avatarLoading = false
              })
              .catch(err => {
                console.log('失败OSS', policy.host, formData)
                that.fileList.splice(
                  that.fileList.findIndex(item => item.name == file.name),
                  1
                )
                if (!that.fileList.length) {
                  that.loading = false
                }
                that.changeProgress()
                that.$message.error(file.name + '上传失败')
              })
          })
          .catch(err => {
            this.$message.error('获取OSS签名失败')
          })
      }
    },
    formatDate(datetime) {
      if (datetime != null) {
        const dateMat = new Date(datetime)
        const year = dateMat.getFullYear()
        const month = dateMat.getMonth() + 1
        const day = dateMat.getDate()
        const hh = dateMat.getHours()
        const mm = dateMat.getMinutes()
        const ss = dateMat.getSeconds()
        const timeFormat =
          year +
          '-' +
          (month < 10 ? '0' : '') +
          month +
          '-' +
          (day < 10 ? '0' : '') +
          day +
          ' ' +
          (hh < 10 ? '0' : '') +
          hh +
          ':' +
          (mm < 10 ? '0' : '') +
          mm +
          ':' +
          (ss < 10 ? '0' : '') +
          ss
        return timeFormat
      }
    },
    setUploadProcess(percent, file, index, btnType) {
      /*       Object.assign(file, { percent })
      this.uploadFileList.splice(index, 1, file)
      let num = 0
      this.uploadFileList.forEach(item => {
        num = num + item.percent
      })
      if (btnType == 'original') {
        let progres = (num / this.uploadFileList.length).toFixed(2) - 0
        if (progres > 99 || progres == 100) {
          progres = 99
        }
        this.topProgress = progres
      } else {
        let progres = (num / this.uploadFileList.length).toFixed(2) - 0
        if (progres > 99 || progres == 100) {
          progres = 99
        }
        this.bottomProgress = progres
      } */
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        ///FileReader类就是专门用来读文件的
        const reader = new FileReader()
        //开始读文件
        //readAsDataURL: dataurl它的本质就是图片的二进制数据， 进行base64加密后形成的一个字符串，
        reader.readAsDataURL(file)
        // 成功和失败返回对应的信息，reader.result一个base64，可以直接使用
        reader.onload = () => resolve(reader.result)
        // 失败返回失败的信息
        reader.onerror = error => reject(error)
      })
    }
  }
}
</script>

<style></style>
