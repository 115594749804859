var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a-upload",
    {
      directives: [
        {
          name: "has",
          rawName: "v-has",
          value: "material:upload",
          expression: "'material:upload'",
        },
      ],
      attrs: {
        name: "file",
        showUploadList: false,
        accept: ".jpg,.psd,.png,.zip",
        multiple: true,
        "before-upload": _vm.beforeUpload,
        "custom-request": _vm.handleRequest,
      },
    },
    [
      !_vm.isTable
        ? _c(
            "a-button",
            {
              staticClass: "mr-3",
              attrs: { loading: _vm.loading, icon: "upload", type: "primary" },
            },
            [_vm._v(_vm._s(_vm.btnText) + " ")]
          )
        : _c(
            "a-spin",
            {
              staticStyle: { "margin-left": "15px" },
              attrs: { spinning: _vm.loading },
            },
            [
              _c("a-icon", {
                staticStyle: { "font-size": "24px" },
                attrs: { slot: "indicator", type: "loading", spin: "" },
                slot: "indicator",
              }),
              _c("a", [_vm._v(_vm._s(_vm.btnText))]),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }